import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { useRecoilState } from 'recoil';
import { userAtom } from '../core/config/atoms';

import { User } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelSettings from 'panels/settings/PanelSettings';

export default function LayoutSetting() {

    const [userData, setUserData] = useRecoilState(userAtom);

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium px-2 mx-2 py-1 my-2 rounded">Settings</motion.h1>
                        <motion.div initial="closed" animate="open" exit="closed" variants={itemVariants}>
                            {
                                Object.keys(userData).length !== 0 && <>{Object.keys(userData.user).length !== 0 && <p className='text-sm uppercase px-1 mx-4 py-1'>{userData.user.fname} {userData.user.lname}</p>}</>
                            }
                        </motion.div>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'setting' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('setting') }}>
                                <User size={20} className='mr-2' /> User Profile
                            </li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'setting':
                                return (<PanelSettings onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
