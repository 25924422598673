import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from '../../core/config/atoms';

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon } from "lucide-react";
import { Dialog } from "@headlessui/react";
import { ScrollArea } from "@/components/ui/scroll-area";

import { toast } from 'react-toastify';
import Moment from 'moment';
import * as XLSX from 'xlsx';

import ServiceAttandance from '../../services/ServiceAttendance';

import TopBar from '../../core/widgets/ui/TopBar';

export default function PanelAttendance() {

    const [userData] = useRecoilState(userAtom);
    const [token] = useRecoilState(tokenAtom);

    const [attandances, setAttandances] = useState([]);
    const [attandancesRecord, setAttandancesRecord] = useState({});

    const [filter, setFilter] = useState({ 'interpreterId': userData._id });

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const attandanceService = new ServiceAttandance();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        switch (val) {
            case "all":
                setFilter({ 'interpreterId': userData._id });
                break;
            case "active":
                setFilter({ 'interpreterId': userData._id, 'status': 'active' });
                break;
            case "open":
                setFilter({ 'interpreterId': userData._id, 'status': 'open' });
                break;
            case "closed":
                setFilter({ 'interpreterId': userData._id, 'status': 'closed' });
                break;
            default:
                break;
        }
    }

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const getTabText = (tab) => {
        switch (tab) {
            case 'active':
                return "In Lobby";
            case 'open':
                return "Marked In";
            case 'closed':
                return "Marked Out";
            default:
                return "All";
        }
    }

    const getStsTitle = (Sts) => {
        switch (Sts) {
            case 'active':
                return "In Lobby";
            case 'open':
                return "Marked In";
            case 'closed':
                return "Marked Out";
            default:
                return "All";
        }
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'active':
                return " bg-blue-100 text-blue-800";
            case 'open':
                return " bg-yellow-100 text-yellow-800";
            case 'closed':
                return " bg-green-100 text-green-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const openImage = (url) => {
        setImageUrl(url);
        setIsOpen(true);
    }

    const getTimeDiff = (date1, date2) => {
        const diffInMilliseconds = Math.abs(new Date(date2) - new Date(date1));
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const getTotalTimeDiff = (records) => {
        let totalDiff = 0;
        records.forEach(element => {
            if (element.status === "closed") {
                const diffInMilliseconds = Math.abs(new Date(element.loginTime) - new Date(element.logoutTime));
                totalDiff = totalDiff + diffInMilliseconds;
            }
        });

        const hours = Math.floor(totalDiff / (1000 * 60 * 60));
        const minutes = Math.floor((totalDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((totalDiff % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const getExportItems = () => {
        let items = [];
        attandances.forEach((item) => {
            items.push({
                interpreterId: item.interpreterId,
                loginTime: item.loginTime,
                logoutTime: item.logoutTime,
                ticketPriority: item.ticketPriority,
                status: item.status,
                timestamp: Moment(item.updatedAt).format('DD, MMM yyyy - hh:mm:ss')
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "tickets");
        XLSX.writeFile(workbook, "Attendance Record.xlsx");
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = () => {
        let headers = ['interpreterId, loginTime, logoutTime, ticketPriority, status, timestamp'];
        let usersCsv = attandances.reduce((acc, item) => {
            const { interpreterId, loginTime, logoutTime, ticketPriority, status, updatedAt } = item
            acc.push([interpreterId, loginTime, logoutTime, ticketPriority, status, updatedAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'Attendance Record.csv',
            fileType: 'text/csv',
        });
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : "",
                "type": "interpreter",
                "uid": userData._id,
            };
            var res = await attandanceService.getAllAttandance(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setAttandances(res.data.data);
                const groupedRecords = res.data.data.reduce((acc, record) => {
                    const date = new Date(record.loginTime).toLocaleDateString();
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(record);
                    return acc;
                }, {});
                setAttandancesRecord(groupedRecords);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : "",
                "type": "interpreter",
                "uid": userData._id,
            };
            var res = await attandanceService.getAllAttandance(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setAttandances(res.data.data);
                const groupedRecords = res.data.data.reduce((acc, record) => {
                    const date = new Date(record.loginTime).toLocaleDateString();
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(record);
                    return acc;
                }, {});
                setAttandancesRecord(groupedRecords);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, date, filter]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Document viewer &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => { setIsOpen(false); }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <div>
                        <img src={imageUrl ? imageUrl : ""} alt='Document' className='w-full rounded border-2' />
                    </div>
                </div>
            </Dialog>
            <ScrollArea className='h-screen w-full'>
                <div className="w-full h-full py-3 px-3">
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                                <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                    Attendance Master
                                </h2>
                                <TopBar />
                            </div>
                            <div class="w-full flex justify-between items-center">
                                <div class="w-full">
                                    <div className="p-2 mt-2">
                                        <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'active' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('active') }}>In Lobby</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'open' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('open') }}>Marked In</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'closed' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('closed') }}>Marked Out</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={8}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <form className="items-center py-2 col-span-3">
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button id="date" variant={"outline"} className={cn("w-[300px] justify-start text-left font-normal", !date && "text-muted-foreground")}>
                                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                                {
                                                                    date?.from ? (
                                                                        date.to ? (<>
                                                                            {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                            {Moment(date.to).format('MMM DD, yyyy')}
                                                                        </>) : (
                                                                            Moment(date.from).format('MMM DD, yyyy')
                                                                        )
                                                                    ) : (<span>Pick a date</span>)
                                                                }
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar initialFocus mode="range" defaultMonth={date?.from} selected={date} onSelect={onDateChange} numberOfMonths={2} />
                                                        </PopoverContent>
                                                    </Popover>
                                                </form>
                                                <div className='col-span-6 flex items-center'></div>
                                                <div className='col-span-2 flex items-center'>
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger className='w-full bg-white'>
                                                            <button className="w-full py-1 px-4 h-10 rounded-md border text-left"> Status: <span className='text-prime text-sm'>{getTabText(tab)}</span> </button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                changeTab('all');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">All</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                changeTab('active');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">In Lobby</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                changeTab('open');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Marked In</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                changeTab('closed');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Marked Out</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                                <div className='col-span-1 flex items-center'>
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger>
                                                            <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                exportToExcel();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                exportToCsv();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Time - In
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Time - Out
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Photo
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Working Hours
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Total Hours
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Ticket Handled
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        Object.entries(attandancesRecord).length !== 0 && Object.entries(attandancesRecord).map(([date, dateRecords]) => {
                                            return dateRecords.map((record, index) => {
                                                return <tr className="hover:bg-gray-100" key={record._id}>
                                                    {
                                                        index === 0 && <td className="px-6 py-4 whitespace-nowrap border" rowSpan={dateRecords.length}>
                                                            <div className="text-sm font-bold text-gray-900">
                                                                <div>{Moment(date).format('DD MMM yyyy')}</div>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td className="px-6 py-4 whitespace-nowrap border">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            <div>{Moment(record.loginTime).format('hh:mm a')}</div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap border">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            <div>{(record.status === 'active' || record.status === 'open') ? "-" : Moment(record.logoutTime).format('hh:mm a')}</div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap border">
                                                        {
                                                            record.status !== 'active' && <div className="text-sm font-bold text-blue-600 cursor-pointer" onClick={() => { openImage(record.profileMedia.fileurl); }}>
                                                                {record.profileMedia !== undefined ? <div>View Image</div> : <div>-</div>}
                                                            </div>
                                                        }
                                                        {
                                                            record.status === 'active' && <div className="text-sm font-bold text-blue-600 cursor-pointer">
                                                                <div>-</div>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            <div>{(record.status === 'active' || record.status === 'open') ? "-" : getTimeDiff(record.loginTime, record.logoutTime)}</div>
                                                        </div>
                                                    </td>
                                                    {
                                                        index === 0 && <td className="px-6 py-4 whitespace-nowrap border" rowSpan={dateRecords.length}>
                                                            <div className="text-sm font-bold text-gray-900">
                                                                <div>{getTotalTimeDiff(dateRecords)}</div>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td className="px-6 py-4 whitespace-nowrap border">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            <div>{record.ticket}</div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap border">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(record.status)}`}>
                                                            {getStsTitle(record.status)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            });
                                        })
                                    }
                                    {
                                        attandances.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="8">
                                                <div className="text-sm text-red-600 text-center">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table >
                            <div className='flex py-2 px-4 items-center bg-blue-50'>
                                <p className='text-sm font-serif'>Show rows per page</p>
                                <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                </select>
                                <div className='flex-grow'></div>
                                <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            </div>
                        </div >
                    </div >
                </div>
            </ScrollArea >
        </>
    )
}
