import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceTicket {
    lookupService = new APIServiceLookUp();

    async getAllTicket(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}ticket/getAllTicket`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTicketById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}ticket/getTicketById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addReviewByRoomId(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}ticket/addReviewByRID`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}