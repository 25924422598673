import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, authAtom, tokenAtom, attandanceAtom } from '../../core/config/atoms';

import { Pencil } from 'lucide-react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';
import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../../services/serviceAuth';
import ServiceAttendance from '../../services/ServiceAttendance';
import ServiceUtility from '../../services/serviceUtility';
import ServiceProfiles from '../../services/serviceProfiles';

const allowedExtensions = ["png", "jpeg"];

export default function PanelSettings(props) {

    const [userData, setUserData] = useRecoilState(userAtom);
    const [token, setToken] = useRecoilState(tokenAtom);
    const [attandance, setAttandance] = useRecoilState(attandanceAtom);
    const [, setAuthStatus] = useRecoilState(authAtom);

    const [file, setFile] = useState(null);
    const [previews, setPreviews] = useState(null);

    const [isOpenProfile, setIsOpenProfile] = useState(false);
    const [isOpenEmail, setIsOpenEmail] = useState(false);
    const [isOpenUsername, setIsOpenUsername] = useState(false);
    const [isOpenPassword, setIsOpenPassword] = useState(false);
    const [isOpenDisable, setIsOpenDisable] = useState(false);

    const [submit, setSubmit] = useState(false);

    var A = { email: '', password: '' };
    var E = { fullname: '', password: '' };
    var C = { opass: '', npass: '', cpass: '' };
    var D = { msg: '' };
    var B = {};

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const attendanceService = new ServiceAttendance();
    const utilityService = new ServiceUtility();
    const profileService = new ServiceProfiles();

    const formVSchemaA = Yup.object().shape({
        email: Yup.string().email('Please enter valid email address').required('This information is required'),
        password: Yup.string().required('This information is required'),
    });

    const formVSchemaC = Yup.object().shape({
        opass: Yup.string().required('This information is required'),
        npass: Yup.string().required('This information is required'),
        cpass: Yup.string().required('This information is required'),
    });

    const formVSchemaD = Yup.object().shape({
        msg: Yup.string().required('This information is required'),
    });

    const formVSchemaE = Yup.object().shape({
        fullname: Yup.string().required('This information is required'),
        password: Yup.string().required('This information is required'),
    });

    const formVSchemaB = Yup.object().shape({
    });

    const getInitValues = () => {
        if (isOpenEmail) {
            return A;
        }
        if (isOpenPassword) {
            return C;
        }
        if (isOpenDisable) {
            return D;
        }
        if (isOpenUsername) {
            return E;
        }
        if (isOpenProfile) {
            return B;
        }
        return A;
    }

    const getInitSchema = () => {
        if (isOpenEmail) {
            return formVSchemaA;
        }
        if (isOpenPassword) {
            return formVSchemaC;
        }
        if (isOpenDisable) {
            return formVSchemaD;
        }
        if (isOpenUsername) {
            return formVSchemaE;
        }
        if (isOpenProfile) {
            return formVSchemaB;
        }
        return formVSchemaA;
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setErrors } = useFormik({
        initialValues: getInitValues(),
        validationSchema: getInitSchema(),
        enableReinitialize: true,
        onSubmit: values => {
            if (isOpenDisable) {
                if (values.msg !== "DISABLE") {
                    setErrors({ ...errors, msg: 'Please enter valid value.' });
                } else {
                    setSubmit(true);
                    let body = {
                        uid: userData.userId,
                        'status': 'disabled'
                    };
                    profileService.updateInterpreter(body, token).then((res) => {
                        if (res.status) {
                            handleReset();
                            setIsOpenDisable(false);
                            logout();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setSubmit(false);
                    });
                }
            }
            if (isOpenEmail) {
                setSubmit(true);
                let body = {
                    uid: userData.userId,
                    password: values.password,
                    data: {
                        email: values.email,
                    }
                };
                authService.IDPUserUpdate(body, token).then((res) => {
                    if (res.status) {
                        handleReset();
                        setIsOpenEmail(false);
                        let tempData = { ...userData, user: res.data };
                        setUserData(tempData);
                        toast.success(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenPassword) {
                if (values.npass != values.cpass) {
                    setErrors({ ...errors, cpass: 'Password did not match.' });
                    return;
                }
                setSubmit(true);
                let body = {
                    uid: userData.userId,
                    opass: values.opass,
                    npass: values.npass
                };
                authService.IDPUserPasswordUpdate(body, token).then((res) => {
                    if (res.status) {
                        handleReset();
                        setIsOpenPassword(false);
                        logout();
                        toast.success('Password updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenUsername) {
                setSubmit(true);
                let names = values.fullname.split(' ');
                let body = {
                    uid: userData.userId,
                    password: values.password,
                    data: {
                        "fname": names[0],
                        "mname": names.length > 2 ? names[1] : "NA",
                        "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                    }
                };
                authService.IDPUserUpdate(body, token).then((res) => {
                    if (res.status) {
                        handleReset();
                        setIsOpenUsername(false);
                        let tempData = { ...userData, user: res.data };
                        setUserData(tempData);
                        toast.success(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenProfile) {
                if (file !== null) {
                    setSubmit(true);
                    if (userData.profileMedia.fileurl === undefined) {
                        var formDataA = new FormData();
                        formDataA.append("path", 'user');
                        formDataA.append("doc", file);

                        utilityService.uploadFile(formDataA, token).then((res) => {
                            if (res.status) {
                                let body = {
                                    'uid': userData._id,
                                    'data': {
                                        'profileMedia': res.data,
                                    }
                                }
                                profileService.updateInterpreter(body, token).then((resB) => {
                                    if (resB.status) {
                                        handleReset();
                                        setFile(null);
                                        setPreviews(null);
                                        setIsOpenProfile(false);
                                        setUserData(resB.data);
                                    } else {
                                        toast.error(resB.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    }
                                    setSubmit(false);
                                });
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    } else {
                        var formDataA = new FormData();
                        formDataA.append("path", userData.profileMedia.fileurl);
                        formDataA.append("doc", file);

                        utilityService.replaceFile(formDataA, token).then((res) => {
                            if (res.status) {
                                let body = {
                                    'uid': userData._id,
                                    'data': {
                                        'profileMedia': res.data,
                                    }
                                }
                                profileService.updateInterpreter(body, token).then((resB) => {
                                    if (resB.status) {
                                        handleReset();
                                        setFile(null);
                                        setPreviews(null);
                                        setIsOpenProfile(false);
                                        setUserData(resB.data);
                                    } else {
                                        toast.error(resB.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                    }
                                    setSubmit(false);
                                });
                            } else {
                                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    }
                }
            }
        }
    });

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setFile(inputFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPreviews(reader.result);
        };
        reader.readAsDataURL(inputFile);
    };

    const deleteProfile = () => {
        let body = {
            'path': userData.profileMedia.fileurl
        }
        utilityService.deleteFile(body, token).then((res) => {
            let body = {
                'uid': userData._id,
                'data': {
                    'profileMedia': {},
                }
            }
            profileService.updateInterpreter(body, token).then((resB) => {
                if (resB.status) {
                    setUserData(resB.data);
                    handleReset();
                    setFile(null);
                    setPreviews(null);
                    setIsOpenProfile(false);
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
            });
        });
    }

    const logout = () => {
        let body = {
            'aid': attandance._id,
            'data': {
                "logoutTime": moment(Date.now()).toISOString(),
                "status": 'closed',
            }
        }
        attendanceService.updateAttendance(body, token).then((resA) => {
            setAttandance({});
            setToken({});
            setAuthStatus('default');
            setUserData({});
            navigate('/');
        });
    }

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                {
                    Object.keys(userData).length !== 0 && <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> User Profile
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                        <div className="col-span-12 mt-4">
                            <div className="intro-y w-full shadow-lg">
                                <div className='h-28 bg-prime rounded-t-lg relative'>
                                    <div className='h-28 bg-prime rounded-t-lg relative'>
                                        {
                                            userData.profileMedia !== undefined && <div className='w-24 h-24 rounded-full overflow-hidden border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                                <img src={Object.keys(userData.profileMedia).length === 0 ? "https://storage.googleapis.com/ish-prod/ish-assets/default.jpeg" : userData.profileMedia.fileurl} alt='User Image' className='w-24 h-24 rounded-full object-cover' />
                                            </div>
                                        }
                                        {
                                            userData.profileMedia === undefined && <div className='w-24 h-24 bg-prime rounded-full flex items-center justify-center overflow-hidden border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                                <img src="https://storage.googleapis.com/ish-prod/ish-assets/default.jpeg" alt='User Image' className='w-24 h-24 rounded-full object-cover' />
                                            </div>
                                        }
                                        <div className='bg-prime w-7 h-7 rounded-full flex items-center justify-center border text-white border-white absolute cursor-pointer' style={{ bottom: '-3rem', left: '1rem' }} onClick={() => {
                                            setIsOpenProfile(true);
                                        }}>
                                            <Pencil size={14} />
                                        </div>
                                    </div>
                                </div>
                                <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                    <div className='flex items-center justify-between mt-1 mb-4'>
                                        <div className='text-2xl font-bold ml-24'>{userData.user.fname}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}<span className='text-lg text-gray-600'>(INTERPRETER)</span></div>
                                    </div>
                                    <div className='bg-gray-00 mt-2 p-4 rounded'>
                                        <div className='flex items-center justify-between'>
                                            <div>
                                                <div className='text-xs text-gray-600'>USERNAME</div>
                                                <div className='text-lg text-black'>{userData.user.fname}{userData.user.mname === "NA" ? "" : ` ${userData.user.mname}`}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}</div>
                                            </div>
                                            <div className='cursor-pointer bg-prime text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                                setIsOpenUsername(true);
                                            }}>Edit</div>
                                        </div>
                                        <div className='flex items-center justify-between mt-3'>
                                            <div>
                                                <div className='text-xs text-gray-600'>Email</div>
                                                <div className='text-base text-black'>{userData.user.email}</div>
                                            </div>
                                            <div className='cursor-pointer bg-prime text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                                setIsOpenEmail(true);
                                            }}>Edit</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <h2 className='text-2xl font-medium truncate'>Password and Authentication</h2>
                        <div className='cursor-pointer bg-prime text-base font-serif mt-4 w-40 rounded px-2 py-2 text-white text-center' onClick={() => {
                            setIsOpenPassword(true);
                        }}>
                            Change Password
                        </div>
                        <hr className='my-4' />
                        <h2 className='text-2xl font-medium truncate'>Account Logout</h2>
                        <h2 className='text-base truncate'>Disabling this account means you can recover it after admin permission only.</h2>
                        <div className='flex'>
                            <div className='border-2 cursor-pointer border-red-400 text-red-400 text-base font-serif mt-4 w-40 rounded px-2 py-2 text-center' onClick={() => { logout() }}>
                                Logout
                            </div>
                        </div>
                    </div>
                }
                <div className='col-span-12 h-screen w-full'>
                    <div className="w-full h-full p-2 overflow-hidden">
                        <Dialog open={isOpenEmail} onClose={() => {
                            setIsOpenEmail(false);
                            handleReset();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenEmail(false);
                                        handleReset();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your email address<sup className="text-red-600">*</sup></label>
                                        <input type="email" id="email" value={values.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. jhon.deo@gmail.com" onChange={handleChange} />
                                        {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your current password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenPassword} onClose={() => {
                            setIsOpenPassword(false);
                            handleReset();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenPassword(false);
                                        handleReset();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your current password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="opass" value={values.opass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.opass && touched.opass) && <p className='text-xs text-red-400 mt-1'>{errors.opass}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your new password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="npass" value={values.npass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.npass && touched.npass) && <p className='text-xs text-red-400 mt-1'>{errors.npass}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Confirm password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="cpass" value={values.cpass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.cpass && touched.cpass) && <p className='text-xs text-red-400 mt-1'>{errors.cpass}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenDisable} onClose={() => {
                            setIsOpenDisable(false);
                            handleReset();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenDisable(false);
                                        handleReset();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm text-gray-900">To disable account type the word <span className='font-bold'>DISABLE</span><sup className="text-red-600">*</sup></label>
                                        <input type="text" id="msg" value={values.msg} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 focus:outline-none focus:shadow focus:shadow-blue-500/50 focus:border-blue-400 uppercase" maxLength={7} placeholder="DISABLE" onChange={handleChange} />
                                        {(errors.msg && touched.msg) && <p className='text-xs text-red-400 mt-1'>{errors.msg}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className={`text-white focus:ring-4 focus:outline-none font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-800`} disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Disable"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenUsername} onClose={() => {
                            setIsOpenUsername(false);
                            handleReset();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenUsername(false);
                                        handleReset();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">First Name<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="fullname" value={values.fullname} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Jhon Harword Deo" onChange={handleChange} />
                                        {(errors.fullname && touched.fullname) && <p className='text-xs text-red-400 mt-1'>{errors.fullname}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your current password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenProfile} onClose={() => {
                            setIsOpenProfile(false);
                            handleReset();
                            setFile(null);
                            setPreviews(null);
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenProfile(false);
                                        handleReset();
                                        setFile(null);
                                        setPreviews(null);
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="col-span-6 md:col-span-2">
                                        <div className="py-2 bg-white">
                                            <div>
                                                {
                                                    file !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={previews} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                setFile(null);
                                                                setPreviews(null);
                                                            }}>
                                                                <span>Remove</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                <span>Replace</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file === null && (userData.profileMedia !== undefined ? Object.keys(userData.profileMedia).length === 0 ? <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                    <span>Upload a file</span>
                                                                    <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                </label>
                                                                <p className="pl-1">or drag and drop</p>
                                                            </div>
                                                            <p className="text-xs text-gray-500">
                                                                PNG, JPG, PDF up to 1MB
                                                            </p>
                                                        </div>
                                                    </div> : <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className="w-full flex items-center justify-center py-4">
                                                            <img src={userData.profileMedia.fileurl} className='w-[400px] h-[400px]' />
                                                        </div>
                                                        <hr />
                                                        <div className="flex justify-between mt-2">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                deleteProfile();
                                                            }}>
                                                                <span>Remove</span>
                                                            </label>
                                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                <span>Replace</span>
                                                                <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                            </label>
                                                        </div>
                                                    </div> : <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                    <span>Upload a file</span>
                                                                    <input type="file" name='pFile' className="sr-only" onChange={(e) => { handleFileChange(e) }} />
                                                                </label>
                                                                <p className="pl-1">or drag and drop</p>
                                                            </div>
                                                            <p className="text-xs text-gray-500">
                                                                PNG, JPG, PDF up to 1MB
                                                            </p>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-center items-center'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "UPLOAD AND UPDATE"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <div className="m-2">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
