import React, { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom, attandanceAtom, tokenAtom } from '../../config/atoms';

import { Tv2, Inbox, Settings, LogOut, Presentation } from 'lucide-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';

import ServiceAttendance from '../../../services/ServiceAttendance';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);
    const [attandance, setAttandance] = useRecoilState(attandanceAtom);
    const [token, setToken] = useRecoilState(tokenAtom);

    const navigate = useNavigate();

    const attendanceService = new ServiceAttendance();

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const logout = () => {
        let body = {
            'aid': attandance._id,
            'data': {
                "logoutTime": moment(Date.now()).toISOString(),
                "status": 'closed',
            }
        }
        attendanceService.updateAttendance(body, token).then((resA) => {
            setAuthStatus('default');
        });
    }

    useEffect(() => {
        if (authStatus === 'default') {
            setAttandance({});
            setToken({});
            setUserData({});
            navigate('/');
        }
    }, [authStatus, navigate]);

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-prime hidden xs:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('main') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-white rounded-full mx-[20%] my-1'></div>
                <ul className="flex flex-col items-center h-[90%]">
                    <Tippy content="Interpreter Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "main" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('main') }}>
                            <Tv2 size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Tickets Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === 'ticket' ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('ticket') }}>
                            <Inbox size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Attendance Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === 'attendance' ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('attendance') }}>
                            <Presentation size={20} />
                        </li>
                    </Tippy>
                    <div className='flex-grow'></div>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === 'setting' ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-red-600 text-white hover:text-white`} onClick={() => { logout() }}>
                            <LogOut size={20} />
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
