import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import PanelDev from '../panels/dev/PanelDev';

import PanelAttendance from '../panels/attendance/PanelAttendance';

export default function LayoutAttendance() {
    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'attendance':
                                return (<PanelAttendance onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
