import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { userAtom, attandanceAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import { AnimatePresence, motion } from "framer-motion";
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import PaneAttandance from './panes/PaneAttandance';
import PaneMeet from './panes/PaneMeet';

import socket from '../../core/config/socket';

export default function PanelMain(props) {

    const [userData] = useRecoilState(userAtom);
    const [attandance] = useRecoilState(attandanceAtom);

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const [queue, setQueue] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const paneMeetRef = useRef();

    const Open = () => {
        setIsOpen(true);
    }

    const joinTicket = async (id, status) => {        
        if (status === "open" || status === "iwait") {
            paneMeetRef.current.joinMeet(id);
        } else {
            toast.warn("Ticked already been assinged to an agent.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'open':
            case 'iwait':
                return "bg-blue-600";
            case 'pwait':
            case 'live':
                return "bg-yellow-600";
            case 'closed':
                return "bg-green-600";
            case 'hang':
                return "bg-red-600";
            default:
                return "bg-gray-600";
        }
    }

    const getStsText = (status) => {
        switch (status) {
            case 'open':
                return "Open";
            case 'iwait':
                return "Wait for I";
            case 'pwait':
                return "Wait for P";
            case 'live':
                return "Live";
            case 'closed':
                return "Closed";
            case 'hang':
                return "Hang";
            default:
                return "NA";
        }
    }

    useEffect(() => {
        socket.emit('getQueue', {}, (que) => {
            console.log(que);
            setQueue(que);
        });
        socket.on('updateQueue', (que) => {
            console.log(que);
            setQueue(que);
        });
    }, []);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '28rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <ScrollArea>
                            <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-lg font-medium pt-4 ml-6">Tickets</motion.h1>
                            <hr className='my-2' />
                            <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="flex flex-col items-start mt-2 w-[20rem] mx-auto">
                                {
                                    queue.map((que) => {
                                        return <li className="cursor-pointer text-base text-black bg-white p-2 w-full rounded-md border my-2" key={que.position}>
                                            <div className=''>
                                                <div className='flex items-center justify-between'>
                                                    <h3 className='text-base font-medium'>{que.name}</h3>
                                                    <h3 className='text-xs bg-prime text-white px-3 py-1 rounded-md'>{que.doc.ticketLang}</h3>
                                                </div>
                                                <p className='text-sm my-1 uppercase text-prime font-medium'>{que.doc.partnerDetails.companyName}</p>
                                                <p className='text-xs my-1 uppercase font-medium'>Department - {que.doc.dept.title}</p>
                                                <p className='text-xs text-gray-900 mt-4 mb-1 font-medium'>Message</p>
                                                <p className='text-xs text-gray-700 my-1'>{que.msg}</p>
                                                <hr className='my-2' />
                                                <div className='flex items-center justify-between'>
                                                    <div className={`${getStsCss(que.status)} text-xs rounded-md p-1 w-[100px] text-center text-white`}>{getStsText(que.status)}</div>
                                                    {
                                                        (que.status === "open" || que.status === "iwait") && <button type="button" className="text-blue-600 hover:text-blue-800 cursor-pointer text-sm float-right" onClick={() => { joinTicket(que.roomid, que.status) }}>
                                                            JOIN NOW
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                                {
                                    queue.length === 0 && <li className="cursor-pointer text-base text-black bg-white p-2 w-full rounded-md hover:shadow-md border my-2">
                                        <div className=''>
                                            <h3 className='text-base font-medium text-center'>No new tickets</h3>
                                        </div>
                                    </li>
                                }
                            </motion.ul>
                        </ScrollArea>
                    </motion.div>
                }
            </AnimatePresence>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { setIsOpen(!isOpen) }}></motion.i> {userData.user.fname}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-2 h-screen">
                        <div className='w-full h-[86%] px-2 flex items-center justify-center'>
                            <div className='h-full w-full grid grid-cols-12 gap-4 my-2 md:my-4'>
                                {attandance.status !== 'open' && <PaneAttandance Open={Open} />}
                                {attandance.status === 'open' && <PaneMeet ref={paneMeetRef} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
